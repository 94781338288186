import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import rpc from './rpc'
import router from "./router";
import Vuex from 'vuex'
import VueApexCharts from "vue-apexcharts";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    user: null,
  },
})

new Vue({
  rpc,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
