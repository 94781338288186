import Collector from "./collector";

export default class User {
    login
    isAdmin
    collector

    constructor(data = {}) {
        this.login = data.login || null
        this.isAdmin = data.isAdmin || false
        this.collector = new Collector(data.collector || {})
    }

    authorized() {
        return this.login !== null
    }

    authorizedAdmin() {
        return this.authorized() && this.isAdmin
    }

    authorizedCollector() {
        return this.authorized() && !this.isAdmin
    }
}
